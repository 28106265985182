import React, { Component } from 'react';
import "../css/Nav.css";

class NavButton extends Component {

    render() {

        return (
            <div>
                {/*<a href="#" className="NavButton" onClick={this.props.clicked}>{this.props.buttonName}</a>*/}
                <a href="#" className="NavButton" onClick={this.props.clicked}>{this.props.buttonName}</a>
            </div>
        );
    }
}

export default NavButton;