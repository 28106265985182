import React, { Component } from 'react';
import Question from './Question';
import Answer from './Answer';
import NextButton from './NextButton';
import RestartButton from './Restart';
import Error from './Error';
import '../css/Card.css';
import Speech from 'speak-tts';

class Card extends Component {

    state = {
        showAnswer: false,
    }

    showAnswerToggle = () => {

        if (!this.props.endOfDeck && !this.state.showAnswer) {
            this.setState({
                showAnswer: true
            }, () => { this._init() })
        }
    }

    hideAnswerToggle = () => {
        this.setState({
            showAnswer: false
        })
        this.props.nextClick();

    }



    _init = () => {
        const speech = new Speech();
        speech
            .init({
                volume: 1,
                lang: "en-US",
                rate: 1,
                pitch: 1,
                voice: null,
                //'splitSentences': false,
                listeners: {
                    onvoiceschanged: voices => {
                        //console.log("Voices changed", voices);
                    }
                }
            })
            .then(data => {
                //console.log("Speech is ready", data);
                //this.addVoicesList(data.voices);
                this.prepareSpeakButton(speech);
            })
            .catch(e => {
                console.error("An error occured while initializing : ", e);
            });
        {/*
            const text = speech.hasBrowserSupport()
                ? "Hurray, your browser supports speech synthesis"
                : "Your browser does NOT support speech synthesis."
            document.getElementById("support").innerHTML = text;*/
        }
    }


    prepareSpeakButton = (speech) => {
        const speakButton = document.getElementById("Read");
        //const languages = document.getElementById("languages");
        if (this.props.showAnswerFlag) {
            speakButton.addEventListener("click", () => {
                //const language = "en";
                //const voice = "Fiona";
                //if (language) speech.setLanguage(languages.value);
                //if (voice) speech.setVoice(voice);
                speech
                    .speak({
                        text: this.props.voice,
                        queue: false,
                        listeners: {
                            onstart: () => {
                                //console.log("Start utterance");
                                //console.log(this.state.qaList[this.state.count].value)
                            },
                            onend: () => {
                                //console.log("End utterance");
                            },
                            onresume: () => {
                                //console.log("Resume utterance");
                            },
                            onboundary: event => {
                                /*console.log(
                                    event.name +
                                    " boundary reached after " +
                                    event.elapsedTime +
                                    " milliseconds."
                                );*/
                            }
                        }
                    })
                    .then(data => {
                        //console.log("Success !", data);

                    })
                    .catch(e => {
                        console.error("An error occurred :", e);
                    });
            });
        }
    }




    render() {

        return (

            <div className='Card'>
                <div onClick={this.showAnswerToggle}>
                    <div className="LessonBanner">
                        <span className="LessonBannerText">{this.props.lessonName}</span>
                    </div>

                    <br></br>
                    {this.props.endOfDeck ?
                        <Error message="End of deck"></Error> : null
                    }
                    {!this.props.endOfDeck ?
                        <Question question={this.props.question}></Question> : null
                    }
                    <br></br>
                    {this.props.showAnswerFlag ?
                        <div>
                            {
                                this.state.showAnswer ?
                                    <div>
                                        <Answer answer={this.props.answer}></Answer>
                                        <br></br>

                                        <button title="Read out Loud" className="btn" id="Read"></button>
                                    </div> : null
                            }
                        </div> : null
                    }

                </div>
                <br></br>
                {
                    !this.props.endOfDeck ?
                        <NextButton clicked={this.hideAnswerToggle}>Next &raquo;</NextButton> : <RestartButton clicked={this.props.restartClick}>Restart</RestartButton>
                }
            </div >
        );
    }
}

export default Card;