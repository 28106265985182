import React, { Component } from 'react';
import '../css/LessonSelector.css';

class LessonSelector extends Component {

    render() {

        return (
            <div className="LessonSelectorContainer" >
                {(this.props.lessonType === "Easy") ?
                    <div>
                        <label className="label">Easy Lessons</label><br></br>
                        < select className="LessonSelector" onChange={(e) => this.props.changed(e.target.value)}>
                            <option>Select a Deck...</option>
                            <option>Easy1</option>
                            <option>Easy2</option>
                            <option>Easy3</option>
                        </select>
                    </div> : null
                }
                {(this.props.lessonType === "Medium") ?
                    <div>
                        <label className="label">Medium Lessons</label><br></br>
                        < select className="LessonSelector" onChange={(e) => this.props.changed(e.target.value)}>
                            <option>Select a Deck...</option>
                            <option>Medium1</option>
                            <option>Medium2</option>
                            <option>Medium3</option>
                        </select>
                    </div> : null
                }
                {(this.props.lessonType === "Hard") ?
                    <div>
                        <label className="label">Hard Lessons</label><br></br>
                        < select className="LessonSelector" onChange={(e) => this.props.changed(e.target.value)}>
                            <option>Select a Deck...</option>
                            <option>Hard1</option>
                            <option>Hard2</option>
                            <option>Hard3</option>
                        </select>
                    </div> : null
                }
            </div>
        );
    }
}

export default LessonSelector;