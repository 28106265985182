import React, { Component } from 'react';
import NavButton from './NavButton';
import "../css/Nav.css";

class NavBar extends Component {

    render() {

        return (
            <div className="topNavBar">
                <NavButton className="Easy" buttonName="Easy" clicked={(e) => this.props.clicked("Easy")}></NavButton>
                <NavButton className="Medium" buttonName="Medium" clicked={(e) => this.props.clicked("Medium")}></NavButton>
                <NavButton className="Hard" buttonName="Hard" clicked={(e) => this.props.clicked("Hard")}></NavButton>
            </div>
        );
    }
}

export default NavBar;