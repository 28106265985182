import React, { Component } from 'react';
import '../css/NextButton.css';

class Next extends Component {

    render() {

        return (
            <div>
                <a href="#" className="Next" onClick={this.props.clicked}> {this.props.children}</a>
            </div>
        );
    }
}

export default Next;