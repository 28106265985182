import React, { Component } from 'react';
import "../css/Error.css";

class Error extends Component {

    render() {

        return (
            <div>
                <span className="ErrorText">{this.props.message}</span>
            </div>
        );
    }
}

export default Error;