import React, { Component } from 'react';
import '../css/Restart.css';

class Restart extends Component {

    render() {

        return (
            <div>
                <a href="#" className="Restart" onClick={this.props.clicked}> {this.props.children}</a>
            </div>
        );
    }
}

export default Restart;