import React, { Component } from 'react';
import '../css/Footer.css';

class Footer extends Component {

    render() {

        return (
            <div className="Footer">
                <span className="FooterText">&copy; Sinchan Roychowdhury</span>
            </div>
        );
    }
}

export default Footer;