import React, { Component } from 'react';
import './App.css';
import Card from './Custom/js/Card';
import Nav from './Custom/js/NavBar';
import LessonSelector from './Custom/js/LessonSelector';
import Footer from './Custom/js/Footer';

class App extends Component {

  dataLength;
  traversedRecord = [];
  fileName = "";
  qaArray = [];
  showStartButtonFlag = false;
  lessonName = "";
  error = null;

  state = {
    qaListSet: false,
    count: 0,
    randomNumber: Math.floor(Math.random() * 8),
    hasElements: false,
    endOfDeck: false,
    lessonType: "",
    showAnswerFlag: false,
    lessonNotFound: false
  }

  populateQAList = (name) => {
    this.showStartButtonFlag = false;
    this.qaArray = [];
    name.map(qa => {
      this.qaArray = this.qaArray.concat(qa);
    })

    this.populateQAStateFlag();
  }

  populateQAStateFlag = () => {

    this.setState({
      qaListSet: true,
      showAnswerFlag: true
    }, () => {
      this.setState({
        hasElements: true,
        endOfDeck: false
      }, () => {
        console.log(this.state.hasElements);
      })
    });

    this.getStateLength();
    this.traversedRecord = [];

  }

  getStateLength = () => {
    this.dataLength = this.qaArray.length;
  }


  setLessonType = (lessonType) => {
    if (lessonType !== this.state.lessonType) {
      this.setState({
        lessonType: lessonType,
        qaListSet: false,
        count: 0,
        randomNumber: Math.floor(Math.random() * 8),
        hasElements: false,
        endOfDeck: false,
        showAnswerFlag: false,
        lessonNotFound: false
      }, () => console.log(this.state.qaListSet));

    }
    this.showStartButtonFlag = false;
  }

  newLesson = (name) => {
    this.setState({
      qaListSet: false,
      count: 0,
      randomNumber: Math.floor(Math.random() * 8),
      hasElements: false,
      endOfDeck: false,
      showAnswerFlag: false,
      lessonNotFound: false
    }, () => console.log(this.state.qaListSet));
    this.fileName = name;
    this.showStartButtonFlag = true;
  }


  nextElement = () => {

    if (this.traversedRecord.length < this.dataLength) {
      this.generateRandomNumber();
    }
    else {
      this.setState({
        endOfDeck: true
      }, () => { console.log(this.state.endOfDeck) })
    }
  }


  generateRandomNumber = () => {

    let localRandomNumber = Math.floor(Math.random() * (this.dataLength));

    if (this.traversedRecord.indexOf(localRandomNumber) > -1) {
      this.generateRandomNumber();

    }
    else {
      this.traversedRecord.push(localRandomNumber);
      this.setState({ randomNumber: localRandomNumber }, () => { console.log(this.state.randomNumber) });
    }
  }


  getLessonName = () => {
    if (this.fileName !== "Select a Deck...") {
      this.qaArray = [];
      this.getFile(this.fileName).then(
        data => {
          if (typeof data !== 'undefined') {
            this.setState({
              lessonNotFound: false
            }, () => {
              this.lessonName = this.fileName;
              this.fileName = data;
              this.showStartButtonFlag = false;
              this.populateQAList(data);
            })
            //this.lessonName = this.fileName;
            //this.fileName = data;
            //this.showStartButtonFlag = false;
            //this.populateQAList(data);
          }
          else {
            //alert("Sorry! Cannot retrieve the specified lesson data. Please try a different deck.");
            this.setState({
              lessonNotFound: true
            })
          }
        }
      );

    }
  }

  getFile = async (name) => {
    if (this.state.lessonType === "Easy") {
      const lessonFileName = await require('./Data/Easy/' + name + '.json');
      return lessonFileName;
    }
  }


  render() {
    return (
      <div className="App">
        <h1 className="header">Bengali Flashcard</h1>
        <div className="topNav">
          <Nav clicked={this.setLessonType}></Nav>
        </div>

        <div className="CardContainer">
          <div>
            <LessonSelector lessonType={this.state.lessonType} changed={this.newLesson}></LessonSelector>
            {(this.fileName !== "" && this.showStartButtonFlag) ?
              <input type="button" value="Start" className="StartButton" onClick={this.getLessonName}></input> : null}
          </div>
          <br></br>
          {this.state.hasElements ?

            <Card question={this.qaArray[this.state.randomNumber].key}
              answer={this.qaArray[this.state.randomNumber].value}
              voice={this.qaArray[this.state.randomNumber].voice}
              nextClick={this.nextElement}
              endOfDeck={this.state.endOfDeck}
              restartClick={() => this.populateQAList(this.fileName)}
              showAnswerFlag={this.state.showAnswerFlag}
              lessonName={this.lessonName}></Card> : null
          }
          {this.state.lessonNotFound ?
            <div id="LessonNotFound">
              <span>Sorry! Cannot retrieve the specified lesson data. Please try a different deck.</span>
            </div> : null}
        </div>
        <div className="FooterContainer">
          <Footer></Footer>
        </div>
      </div>
    );
  }
}


export default App;