import React, { Component } from 'react';
import '../css/Question.css';

class Question extends Component {

    render() {

        return (
            <div className='Question'>
                <h1>{this.props.question}</h1>
            </div>
        );
    }
}

export default Question;