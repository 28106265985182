import React, { Component } from 'react';
import '../css/Answer.css';

class Answer extends Component {

    render() {
        return (
            <div className='Answer'>
                <h1>{this.props.answer}</h1>
            </div>
        );
    }
}

export default Answer;